import React, { Component } from 'react';
import './../assets/css/main.css'; 
class LandingPage extends Component {
    constructor(props) {
        super(props);  
    } 
    componentWillMount(){ 
    } 
    

    render() { 

        return (
            <div className="container-fluid site-container">
                <div className="splash-wrapper d-flex flex-row align-items-center">
                    <div className="container">
                        <div className="splash-container"> 
                            <div className="splash-body font-white">
                                <span>Transform the your core business with big data and machine learning. </span>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div id="concept" className="section-wrapper">
                    <div className="container">
                        <h2 className="section-header font-white">
                            <span className="section-header-logo logo-bg"></span>
                            <span className="section-header-text">Vision Platform</span>
                        </h2>
                        <div className="section-body">
                            Axrail Vision platform makes it possible for individual to handle end to end computer vision project without data scientist, 
                            developer and human to do tagging on object manually. Instead of doing all these yourself, vision platform will handle 
                            for you, just drop your images to label and create a job, a machine learning model will be trained and you can get machine 
                            learning model as output, you have option to use this production-ready object detection model on edge using SDK on python,
                            iOS and Android, or online API endpoints. 

                            <div className="side-comparison-slider d-none d-lg-block d-xl-block">
                                <div className="old-side">
                                    <img src="/assets/img/old-way.png" />
                                </div>
                                <div className="new-side">
                                    <img src="/assets/img/new-way.png" />
                                </div>
                                <div className="slide-handle"></div>
                            </div>

                            <div className="side-comparison-slider-mobile d-md-block d-lg-none">
                                <div className="swipe">
                                    <img src="assets/img/swipe.gif" />
                                </div>
                                <div className="content">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#newside-tab" role="tab" aria-controls="profile" aria-selected="false">New Way</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#oldside-tab" role="tab" aria-controls="home" aria-selected="true">Old Way</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="newside-tab" role="tabpanel" aria-labelledby="newside-tab">
                                            <img src="assets/img/new-way-noheader.png" />
                                        </div>
                                        <div className="tab-pane fade" id="oldside-tab" role="tabpanel" aria-labelledby="oldside-tab">
                                            <img src="assets/img/old-way-noheader.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="framework" className="section-wrapper-white framework-section d-flex">
                    <div className="container d-flex flex-row flex-wrap align-items-center">
                        <div className="left-section">
                            <div className="left-section-content">
                                <h2 className="section-header font-white">
                                    <span className="section-header-logo logo-bg purple"></span>
                                    <span className="section-header-text font-black">Framework</span>
                                </h2>
                                <div className="section-body font-black">
                                    Instead of building from scratch we have frameworks to accelerate  product development and bring your 
                                    applications to production stage quicker. These frameworks enable you to build web and mobile projects with machine learning 
                                    much faster with better quality. And we have analytic framework that you could tap on for data ingestion, ETL, and 
                                    visualization using tools you familiar. 
                                </div>
                            </div>
                        </div>
                        <div className="right-section align-items-center justify-content-center">
                            <span className="gear-svg">
                                <span className="gear-svg-big"></span>
                                <span className="gear-svg-small"></span>
                            </span>
                        </div>
                    </div>
                </div>

                <div id="workshop" className="section-wrapper-grey-triangle workshop-section d-flex">
                    <div className="container d-flex flex-row flex-wrap align-items-center">
                        <div className="left-section">
                            <div className="left-section-content">
                                <h2 className="section-header font-white">
                                    <span className="section-header-logo logo-bg purple"></span>
                                    <span className="section-header-text font-black">Workshop</span>
                                </h2>
                                <div className="section-body font-black">
                                    Not sure what you can do with emerging technologies? No worries, we have conducted multiple innovation workshop with customers to 
                                    help them discover what business outcome they could achieve using emerging technologies. With our proven 
                                    workshop methodology, we can help you to identify use case and push idea to production within weeks instead of 
                                    months. The goal is to achieve tangible business rather than POC on new technology. 
                                </div>
                            </div>
                        </div>
                        <div className="right-section">
                            <span className="light-bulb"></span>
                        </div>
                        <div className="mobile-section font-black section-body">
                            Not sure what you can do? That's common, we have conducted multiple innovation workshop with customers to 
                            help them discover what business outcome they could achieve using emerging technologies. With our proven 
                            workshop methodology, we can help you to identify use case and push idea to production within weeks instead of 
                            months. The goal is to achieve tangible business rather than POC on new technology. 
                        </div>
                    </div>
                </div> 

                <div id="career" className="section-wrapper gradient">
                    <div className="container">
                        <div className="d-flex flex-row flex-wrap career">
                            <div className="career-info">
                                <div className="title-wrapper">
                                    <span className="title">We are hiring, join us today !</span>
                                    <span className="Axrail-logo-white"></span>
                                </div>
                                <div className="career-message light-text"> 
                                </div>
                            </div>

                            <div className="career-button-wrapper">
                                <a className="clear-button" href="/career">Open positions</a>
                            </div>
                        </div>

                    </div>
                </div> 
            </div>
        )
    }
}

export default LandingPage
