import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import Amplify, { Storage, API, graphqlOperation } from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
, document.getElementById('root'));
