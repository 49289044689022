import React, { Component } from 'react';
import {createSRecord} from '../graphql/mutations'
import Amplify, { Analytics, Storage, API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { Modal,Button,Alert } from 'react-bootstrap'
import Select from 'react-select';
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import '../assets/css/phone-input-style.css'

import PhoneInput from 'react-phone-number-input/react-responsive-ui'

const interestOptions = [
    { value: 'machine learning', label: 'Machine Learning' },
    { value: 'bigdata', label: 'Big Data' },
    { value: 'webdev', label: 'Web Development' },
    { value: 'appdev', label: 'App Development' },
    { value: 'aws', label: 'AWS' },
    { value: 'container', label: 'Container' },
    { value: 'react', label: 'React' },
    { value: 'csharp', label: 'C#,Java,Php' },
    { value: 'python', label: 'Python' },
    { value: 'nodejs', label: 'nodejs' },
    { value: 'azure', label: 'Azure' },
    { value: 'GoogloeCloud', label: 'Google Cloud' },
    { value: 'ux', label: 'UX' }
  ];

  const jobOptions = [
    { value: 'ux', label: 'Frontend developer' },
    { value: 'data', label: 'Data engineer' },
    { value: 'cloud', label: 'Cloud associate' },
    { value: 'mobile', label: 'Mobile developer' },
  ];

class LandingPage extends Component {
    constructor(props) {
        super(props); 
        this.updateHandler = this.updateHandler.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);

        this.state = {
            isDataFetched: false,
            firstName:'', 
            lastName:'', 
            mobile:'+6', 
            superpower:'', 
            email:'', 
            friendCode : "",
            emailValid: false,
            mobileValid : false,
            completed : false,
            firstNameValid: false,
            formValid: false,
            formErrors: {email: '', mobile: '', firstName: ''},
            selectedOption : null,
        }
    } 

    async updateHandler(event){
        event.preventDefault();

        if(this.state.firstName=='' ||this.state.lastName=='' ||this.state.mobile=='')
        {
            this.setState({
                errorMessage : "All fields are required",
                showErrorNotification : true
            })
            
            return;
        }

        this.setState({
            errorMessage : "",
            showErrorNotification : false
        })


        const interests = this.state.selectedInterestOption;
        var interest = ""
        interests.map(item =>{
            interest =  item.value + "," + interest 
        });
        interest = interest.substring(0, interest.length -1)
    
        const jobs = this.state.selectedJobOption;
        var job = ""
        jobs.map(item =>{
            job =  item.value + "," + job 
        });
        job = job.substring(0, job.length -1)
    
        //const id = this.props.location.pathname.split('/')[2];
        //if (id)
        {    
            const ownCode = this.state.mobile.replace('+',"")
            const recordDetails = { 
                firstname: this.state.firstName,
                lastname: this.state.lastName, 
                mobile: this.state.mobile, 
                email: this.state.email, 
                status : 'Created',
                referralcode : ownCode,
                friendcode : this.state.friendCode ? this.state.friendCode: " ",
                interestList : interest, 
                jobList : job,
                superpower : this.state.superpower ? this.state.superpower: " "
                
            };
            const input = {
                input:recordDetails
            }

            console.log(input)
            try
            {
                const newEvent = await API.graphql(graphqlOperation(createSRecord, input));
                console.log(newEvent)
                if (newEvent.data.createSRecord.id){
                    
                    this.setState({
                        showNotification: true,
                        completed : true,
                        verifylink : "/verify/" + newEvent.data.createSRecord.id
                    }) 
                }
               
            }catch (err) {
                console.log(err);
                this.setState({
                    showNotification: false,
                    showErrorNotification: true,
                    errorMessage: "Error occured, unable to save record"
                })
            }
        }
        
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value; 
        //console.log(name + "-" + value)
        this.setState({[name]: value}, 
            () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let mobileValid = this.state.mobileValid;
        let firstNameValid = this.state.firstNameValid;
        let passwordValid = this.state.passwordValid;

        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            //emailValid = true
            fieldValidationErrors.email = emailValid ? '' : ' is invalid';
            
            break;
           case 'mobile':
            mobileValid = value.length > 5;
            //mobileValid = true
            fieldValidationErrors.mobile = mobileValid ? '' : ' is invalid';
            
            break;
          case 'firstName':
            firstNameValid = value.length > 0;
            fieldValidationErrors.firstName = firstNameValid ? '' : ' is required';
            
            break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        emailValid: emailValid,
                        mobileValid: mobileValid,
                        firstNameValid: firstNameValid
                      }, this.validateForm);
      }

      validateForm() {
        console.log(this.state.emailValid)
        console.log(this.state.firstNameValid)
        this.setState({formValid: this.state.mobileValid &&  this.state.emailValid && this.state.firstNameValid});
      }

    componentWillMount(){ 
    } 
    
    handleDismiss(){
        this.setState({
            showNotification: false,
            showErrorNotification: false
        })
    }

    handleInterestChange = (selectedInterestOption) => {
        this.setState({ selectedInterestOption });
        console.log(`Option selected:`, selectedInterestOption);
        selectedInterestOption.map(item => {
            console.log(item)
        })
    }

    handleJobChange = (selectedJobOption) => {
        this.setState({ selectedJobOption });
        console.log(`job selected:`, selectedJobOption);
        selectedJobOption.map(item => {
            console.log(item)
        })
    }

    render() {
        const {verifylink, selectedInterestOption, selectedJobOption, selected,completed,showNotification,showErrorNotification,errorMessage} = this.state 
 

        return (
            <div className="container-fluid site-container">
            <div className="career-header-wrapper d-flex flex-row align-items-center">
                <div className="container">
                    <div className="career-container">
                        <div className="career-header">
                            <h1>Tell us more about you.</h1>
                        </div>
                    </div>
                </div>
            </div>
            {  showNotification ? 
                <Alert variant="success" onClose={this.handleDismiss}>
                    Thanks for your interest, click <a href={verifylink}>here</a> to confirm your mobile number so we could contact you about the job interview.
                </Alert> : null
                }
                {  showErrorNotification ? 
                <Alert variant="danger" onClose={this.handleDismiss}>
                    {errorMessage}
                </Alert> : null
                }
            {
                !completed ? <div className="career-body-wrapper d-flex flex-row align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label htmlFor="firstname">First Name</label>
                                <input type="text" className="form-control" name="firstName" placeholder="First Name" value={this.state.firstName}onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                            <div className="col-sm-6 form-group">
                                <label htmlFor="lastname">Last Name</label>
                                <input type="text" className="form-control" name="lastName" placeholder="Last Name" value={this.state.lastName} onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                            
                        </div> 
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label htmlFor="contact">Mobile</label> 
                                <input type="text" className="form-control" name="mobile" placeholder="Your mobile +6012 1234567" value={this.state.mobile} onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                            <div className="col-sm-6 form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" name="email" placeholder="Email" value={this.state.email} onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div> 

                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label htmlFor="registerNumber">Your skillsets</label>
                                <Select isMulti = {true}
                                        value={selectedInterestOption}
                                        onChange={this.handleInterestChange}
                                        options={interestOptions}
                                    />
                            </div>
                            <div className="col-sm-6 form-group">
                                <label htmlFor="partNumber">Job position</label>
                                <Select isMulti = {true}
                                        value={selectedJobOption}
                                        onChange={this.handleJobChange}
                                        options={jobOptions}
                                    />
                            </div>
                        </div> 

                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label htmlFor="registerNumber">What is your strength</label>
                                <input type="text" className="form-control" name="superpower" placeholder="Tell us your superpower" value={this.state.superpower}onChange={event=>this.setState({superpower:event.target.value})}/>
                            </div> 
                            <div className="col-sm-6 form-group">
                                <label htmlFor="registerNumber">Your friend referral code</label>
                                <input type="text" className="form-control" name="friendCode" placeholder="Optional" value={this.state.friendCode}onChange={event=>this.setState({friendCode:event.target.value})}/>
                            </div> 
                        </div> 
                        
                            <div className="row ">
                                <div className="col-lg-12">
                                    <button type="submit" disabled={!this.state.formValid} className="btn btn-success" style={{marginRight: '10px'}} onClick={this.updateHandler}>Submit</button> 
                                </div>
                            </div>
                        

                        
                    </div>
                </div> : null
            }
            
     
        </div>
        )
    }
}

export default LandingPage
