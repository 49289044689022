// eslint-disable
// this is an auto generated file. This will be overwritten

export const verifyOTP = `query verifyOTP($id: ID!, $otp: String) {
  verifyOTP(id: $id, otp: $otp) { 
    referralcode 
    id
  }
}
`;
export const getSRecord = `query GetSRecord($id: ID!) {
  getSRecord(id: $id) {
    id
    firstname
    lastname
    dob
    mobile
    email
    created
    status
    otp
    referralcode
    interestList
    jobList
    superpower
    friendcode
  }
}
`;
export const listSRecords = `query ListSRecords(
  $filter: ModelSRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listSRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstname
      lastname
      dob
      mobile
      email
      created
      status
      otp
      referralcode
      interestList
      jobList
      superpower
      friendcode
    }
    nextToken
  }
}
`;
export const getLeaderRecord = `query GetLeaderRecord($id: ID!) {
  getLeaderRecord(id: $id) {
    id
    firstname
    lastname
    refercount
    referralcode
  }
}
`;
export const listLeaderRecords = `query ListLeaderRecords(
  $filter: ModelLeaderRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listLeaderRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstname
      lastname
      refercount
      referralcode
    }
    nextToken
  }
}
`;
