// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://7hw4z6uw7ne67pblilavke4myi.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-clfgd6iwj5hzvbomo2vmp3aw6m",
    "aws_content_delivery_bucket": "axrailwebapp-20190514145746-hostingbucket-production",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d3qnwwj62cghee.cloudfront.net"
};


export default awsmobile;
