// eslint-disable
// this is an auto generated file. This will be overwritten

export const createSRecord = `mutation CreateSRecord($input: CreateSRecordInput!) {
  createSRecord(input: $input) {
    id
    firstname
    lastname
    dob
    mobile
    email
    created
    status
    otp
    referralcode
    interestList
    jobList
    superpower
    friendcode
  }
}
`;
export const updateSRecord = `mutation UpdateSRecord($input: UpdateSRecordInput!) {
  updateSRecord(input: $input) {
    id
    firstname
    lastname
    dob
    mobile
    email
    created
    status
    otp
    referralcode
    interestList
    jobList
    superpower
    friendcode
  }
}
`;
export const deleteSRecord = `mutation DeleteSRecord($input: DeleteSRecordInput!) {
  deleteSRecord(input: $input) {
    id
    firstname
    lastname
    dob
    mobile
    email
    created
    status
    otp
    referralcode
    interestList
    jobList
    superpower
    friendcode
  }
}
`;
export const createLeaderRecord = `mutation CreateLeaderRecord($input: CreateLeaderRecordInput!) {
  createLeaderRecord(input: $input) {
    id
    firstname
    lastname
    refercount
    referralcode
  }
}
`;
export const updateLeaderRecord = `mutation UpdateLeaderRecord($input: UpdateLeaderRecordInput!) {
  updateLeaderRecord(input: $input) {
    id
    firstname
    lastname
    refercount
    referralcode
  }
}
`;
export const deleteLeaderRecord = `mutation DeleteLeaderRecord($input: DeleteLeaderRecordInput!) {
  deleteLeaderRecord(input: $input) {
    id
    firstname
    lastname
    refercount
    referralcode
  }
}
`;
