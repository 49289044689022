import React, { Component } from 'react';
import {verifyOTP} from '../graphql/queries'
import { graphqlOperation,API } from 'aws-amplify';
import { Modal,Button,Alert } from 'react-bootstrap'
import moment from 'moment';
class OTPPage extends Component {
    constructor(props) {
        super(props); 
        this.updateHandler = this.updateHandler.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.state = { 
            otp:'', 
            completed: false,
            formErrors: {otp: ''},
        }
    } 

    handleDismiss(){
        this.setState({
            showNotification: false,
            showErrorNotification: false
        })
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;  
        this.setState({[name]: value}, 
            () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let otpValid = this.state.otpValid; 

        switch(fieldName) { 
          case 'otp':
            otpValid = value.length > 0;
            fieldValidationErrors.otp = otpValid ? '' : ' is required';
            
            break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        otpValid: otpValid, 
                      }, this.validateForm);
      }

      validateForm() { 
        this.setState({formValid: this.state.otpValid});
      }
 

    async updateHandler(event){
        event.preventDefault();

        if(this.state.otp=='')
        {
            this.setState({
                errorMessage : "All fields are required",
                showErrorNotification : true
            })
            
            return;
        }

        this.setState({
            errorMessage : "",
            showErrorNotification : false
        })
        
        const id = this.props.location.pathname.split('/')[2];
        if (id)
        {    
            const recordDetails = { 
                otp: this.state.otp, 
                id : id,
            };
            const input = {
                input:recordDetails
            }

            console.log(input)
            try
            {
                const newEvent = await API.graphql(graphqlOperation(verifyOTP, recordDetails));
                console.log(newEvent)
                if (newEvent.data.verifyOTP.id){
                    let referralcode = newEvent.data.verifyOTP.referralcode 
                    this.setState({
                        showNotification: true,
                        completed: true,
                        referralcode : referralcode
                    }) 

                    var now = moment().unix();
                    if (now > 1557900000){
                        this.setState({
                            fairover:true
                        })
                    }
                }
               
            }catch (err) {
                console.log(err);
                var message = err.errors[0].message;
                console.log(message)
                this.setState({
                    showNotification: false,
                    showErrorNotification: true,
                    errorMessage: message//"Error occured, unable to verify OTP"
                })
            }
        }
        
    }
    
    render() {
        const {selected,referralcode,showNotification,showErrorNotification,errorMessage,fairover,completed} = this.state  

        return (
            <div className="container-fluid site-container">
                <div className="career-header-wrapper d-flex flex-row align-items-center">
                    <div className="container">
                        <div className="career-container">
                            <div className="career-header">
                                <h1>Page not found.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            
     
        </div>
        )
    }
}

export default OTPPage
