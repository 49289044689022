import React, { Component } from 'react';

class LandingPage extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            isDataFetched: false,
            searchText:'', 
        }
    } 
    componentWillMount(){ 
    } 
    

    render() {
        const {data, isModalShown, selected,isReadOnly} = this.state 
 

        return (
            <div class="container-fluid site-container">
            <div class="career-header-wrapper d-flex flex-row align-items-center">
                <div class="container">
                    <div class="career-container">
                        <div class="career-header">
                            <h1>Open positions at Axrail</h1>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="career-body-wrapper d-flex flex-row align-items-center">
                <div class="container">
                    <a class="job-item d-flex flex-wrap" href="/career/befeb29c-35c6-4b5b-ad07-8c0ad1a48856">
                        <div class="position-title">Senior Front-End Developer</div>
                        <div class="job-location">
                            <i></i>
                            <span>Singapore/Kuala Lumnpur</span>
                        </div>
                    </a>
                    <a class="job-item d-flex flex-wrap" href="/career/6affdfa7-3ecd-4b11-8458-85ed42fe3bd2">
                        <div class="position-title">Product designer</div>
                        <div class="job-location">
                            <i></i>
                            <span>Singapore/Kuala Lumnpur</span>
                        </div>
                    </a>
                    <a class="job-item d-flex flex-wrap" href="/career/451bede1-04ff-4b4e-8720-cdd85ce37239">
                            <div class="position-title">Data architect</div>
                            <div class="job-location">
                                <i></i>
                                <span>Kuala Lumpur</span>
                            </div>
                        </a>
                </div>
            </div>
     
        </div>
        )
    }
}

export default LandingPage
