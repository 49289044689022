import React, { Component } from 'react'; 

class TopBar extends Component {
    constructor(props){
		super(props); 
		this.state = {
			username: "",
		}
	}
 
	componentDidMount() {
		
	  }

	render(props){ 
		return (
			<div>
			<nav role="navigation" className="nav-bar sticky-top">
				<div className="container">
					<a href="/" className="nav-link-logo logo-bg"></a>
					
				</div>
			</nav>
			<div className="navigation-slider">
				<ul className="navigation-scroll">
					<li>
						<a href="#concept">Concept</a>
					</li>
					<li>
						<a href="#framework">Framework</a>
					</li>
					<li>
						<a href="#workshop">Workshop</a>
					</li>
					<li>
						<a href="#methodology">Methodology</a>
					</li>
					<li>
						<a href="#career">Career</a>
					</li>
					<li className="hide">
						<a href="#contactus">Contact Us</a>
					</li>
				</ul>
			</div>
			</div>
		)
	}
}

export default TopBar;
