import React, { Component } from 'react';


const JobFooter = ({onClick}) => {
    return (
        <div>
           <p>
                If this sounds interesting, we would love to hear from you. Please include whatever info you believe is relevant: resume, GitHub profile, code samples, links to personal projects, etc.
            </p>
            <h2>Benefits</h2>
            <ul>
                <li>Competitive salaries</li>
                <li>Paid time off</li>
                <li>Flexible working hour</li>
                <li>Apple equipment if you prefer</li>
            </ul>
        </div>
    )
}

const BasicReq = ({list}) => {
    return (
        <div>
          
          <ul>
              {
                list.map(item=>{
                    return <li> {item}</li>
                })
              }                            
          </ul>
        </div>
    )
}

class JobDetPage extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            isDataFetched: false,
            searchText:'', 
        }
    }  
 
    componentWillMount(){ 
        let jd1 = {
            title : "Senior Front-End Developer",
            location : "Singapore/Kuala Lumpur",
            shortcut: "mailto:career@Axrail.com?subject=Senior%20Front-End%20Developer",
            desc : "We are a leading company in providing analytics and machine learning services to help enterprise on building a data driven company. We are looking for passionate, hard-working, and talented front-end engineers to innovate in building innovative websites to customers love. You will have an enormous opportunity to make a large impact on the design, architecture, and implementation of cutting edge products used everyday, by people you know. Frontend Developers are an essential part of our Product Team.  Working closely with designers, we implement the user interface of our web app.  We build libraries and abstractions to make our lives easier and build services faster.  We make the most of modern tools like React, ES6, and SASS, and we ensure our UIs work well on all screen sizes.  Some of us specialize in CSS, some in front-end infrastructure, but all of us are JavaScript pros and full-stack developers. ",
            responsibility : [
                'Collaborate with Experience Designers to iterate on the design and implementation of our product',
                'Build efficient and reusable front-end systems and abstractions',
                'Responsible for the development and maintenance of key system features',
                'Will work with other team members to investigate design approaches, prototype new technology and evaluate technical feasibility.',
                'Participate in design and code reviews',
                'Front-end engineers that are able to work in all parts of an application – the backend services, middle tier, and front-end'
            ],
            basic : ["Strong attention to detail. You will be responsible for pixel-perfect implementation of designs",
                    "3+ years of programming experience in JavaScript and/or Java in Linux and/or Windows environment",
                    "Strong knowledge of JavaScript like React.js",
                    "Experience with modern JavaScript libraries and tooling",
                    "Commanding grasp of HTML, CSS, and related web technologies",
                    "Awareness of cross-browser compatibility issues and client-side performance considerations",
                    "Demonstrated design and UX sensibilities",
                    "Curious and have a passion for learning"
                    ],
            prefer : [
                "Strong Computer Science fundamentals ",
                "Development experience of using cloud platform.",
                "Development experience defining, developing and maintaining REST based interfaces",
                "Excellence in technical communication with peers and non-technical cohorts",
                "Strong sense of ownership, urgency, and drive",
                "Ability to achieve stretch goals in a highly innovative and fast paced environment"
            ]
        } 
        let jd2 = {
            title : "Product designer",
            location : "Singapore/Kuala Lumpur",
            shortcut: "mailto:career@Axrail.com?subject=Product%20Designer",
            desc : "We are a leading company in providing analytics and machine learning services to help enterprise on building a data driven company. We are looking for passionate, hard-working, and talented product designer to innovate in building innovative websites to customers love. You will have an enormous opportunity to design products used everyday, by people you know. Product designer are an essential part of our Product Team.  Working closely with engineer, we implement the user interface of our web app.  We build libraries and abstractions to make our lives easier and build services faster.  We make the most of modern tools like Photoshop, illustrator, inVision, Sketch and we ensure our UIs work well on all screen sizes. ",
            responsibility:[
                'Collaborate with Experience engineers and product lead to iterate on the design and implementation of our product',
                'Take UX/UI design from ideation to production such as user flows, journey diagrams, wireframes, sketches, prototypes, layouts, UI assets, and production documents',
                'Develop a simple, clean, and attractive UI/front-end',
                'Will work with other team members to investigate design approaches, prototype new technology and evaluate technical feasibility.'
            ],
            basic : [
                "Strong attention to detail. You will be responsible for pixel-perfect implementation of designs",
                "Ability to take initiative within the remote environment",
                "Comfortable working in a fast-paced iterative/agile design process based on frequent feedback loops",
                "Proven record of high-quality web and/or mobile design and creating engaging digital experiences with a solid portfolio to demonstrate this - experience with data-driven design is a requirement",
                "Solid UI/UX design experience with Photoshop, Illustrator, InDesign, Sketch, Axure, UxPin, InVision",
                "Awareness of cross-browser compatibility issues and client-side performance considerations",
                "Demonstrated design and UX sensibilities",
                "Curious and have a passion for learning",
                "Believe in craftsmanship and repeatedly create elegant and compelling designs."
            ],
            prefer:[ 
                'Strong sense of ownership, urgency, and drive',
                "Ability to achieve stretch goals in a highly innovative and fast paced environment",
                'Excellence in technical communication with peers and non-technical cohorts',
            ]
        }


        let jd3 = {
            title : "Data architect",
            location : "Kuala Lumpur",
            shortcut: "mailto:career@Axrail.com?subject=Data%20Architect",
            desc : "We are a leading company in providing analytics and machine learning services to help enterprise on building a data driven company. We are looking for passionate, hard-working, and talented data architect to innovate in building data solutions that customers love. You will have an enormous opportunity to make a large impact on the design, architecture, and implementation of cutting edge data solutions that change peoples' lives everyday. Data architects are an essential part of our analytics team.  Working closely with product team, we analyze large amount of data and power critical decision making.  We leverage on AWS data technologies to build our data solutions.  We make the most of modern tools like Spark, Sagemaker, Tableau and AWS glue, and we ensure our data technologies are update to latest technology stacks.   ",
            responsibility:[
                'Lead and complete work projects with resourcefulness on right timeline for customers and internal product team',
                'Participate pre-sales on-site visits, understand customer requirements, creating consulting proposals',
                'Coordinating and execute pilots, prototypes and POCs while providing validation for business enhancements',
                'Will work with other team members to investigate design approaches, prototype new technology and evaluate technical feasibility.'
            ],
            basic : [
                'Strong attention to detail. You will be responsible for end to end implementation of your design',
                'Experience in leading a team to solve business problems using technologies.',
                'Expert hands on experience and architectural design with AWS Big stack (S3, EMR, Redshift, Kinesis, Glue, Athena).',
                'Deep knowledge on Spark',
                'Ability to achieve stretch goals in a highly innovative and fast paced environment',
                'Curious and have a passion for learning',
            ],
            prefer:[
                'Strong Computer Science fundamentals',
                'Understanding on deep learning framework like pytorch and tensorflow',
                'Excellence in technical communication with peers and non-technical cohorts',
                'Strong sense of ownership, urgency, and drive',
            ]

        }

        const id = this.props.location.pathname.split('/')[2];
        if (id)
        {
            if (id === "befeb29c-35c6-4b5b-ad07-8c0ad1a48856"){
                this.setState({
                    job : jd1
                })
            }   
            else if (id === "6affdfa7-3ecd-4b11-8458-85ed42fe3bd2"){
                this.setState({
                    job : jd2
                })
            } 
            else if (id === "451bede1-04ff-4b4e-8720-cdd85ce37239"){
                this.setState({
                    job : jd3
                })
            }              

        }


        
    } 
    

    render() {
        const {job} = this.state 

        return (
            <div className="container-fluid site-container">
            <div className="top-header-wrapper d-flex flex-row align-items-center">
            </div>
    
    
            <div className="career-body-wrapper d-flex flex-row align-items-center">
                <div className="container">
                    <div className="job-item d-flex flex-wrap">
                        <div className="job-item-header d-flex">
                            <div className="job-item-left d-flex flex-wrap">
                                <div className="position-title">
                                    <h1>{job.title}</h1>
                                </div>
                                <div className="job-location">
                                    <i></i>
                                    <span>{job.location}</span>
                                </div>
                            </div>
                            <div className="job-item-right d-flex justify-content-center align-items-center">
                                <a href={job.shortcut} className="purple-button">Apply Now</a>
                            </div>
                        </div>
                        <div className="job-item-body">
                            <h2>About Axrail </h2>
                            <p>
                                {job.desc}
                            <br/><br/>
                                We only hire builders, in our definition builders are people who like to invent, people who like to look at different customer experiences and assess what's wrong with them and reinvent them, and people who get that look at the launch as the starting line, not the finish line. We focus on outcome not process. Join us if you think you are builders too !
                            </p>
                            <h2>Responsibilities</h2>
                            <BasicReq list={job.responsibility} />

                            <h2>Basic Qualifications</h2>
                            <BasicReq list={job.basic} />
                            
                            <h2>Preferred Qualifications</h2>
                            <BasicReq list={job.prefer} />
                            
                            <JobFooter />
                        </div>
                    </div>
                </div>
            </div>
     
        </div>
        )
    }
}

export default JobDetPage
