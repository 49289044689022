import React, { Component } from 'react';
import {listLeaderRecords} from '../graphql/queries'
import Amplify, { Analytics, Storage, API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { Modal,Button,Alert } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
let listData =[];
class LandingPage extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            isDataFetched: false,
            searchText:'',
            data : null, 
        }
    } 
    componentWillMount(){ 
        this.getListRecords("","")

        var now = moment().unix();
        if (now > 1557900000){
            this.setState({
                fairover:true
            })
        }
        console.log(now)

    } 

    async getListRecords(lastToken, currentPageIndex){
        const params = { 
            limit:10
        };
        if (lastToken){
            params.nextToken = lastToken
        }
        const listResponse = await API.graphql(graphqlOperation(listLeaderRecords, params));
        if(listResponse.data.listLeaderRecords){ 

            listData = listResponse.data.listLeaderRecords.items.map(item=>{
                const container = {};
                container.id = item.id; 
                container.refercount = item.refercount;
                container.firstname = item.firstname;
                container.lastname = item.lastname;  
                return container; 
            });
            let token = listResponse.data.listLeaderRecords.nextToken;
            console.log(token)
            if (!lastToken){
                //first batch case
                this.setState({
                    firstpage:listData,
                    data:listData, 
                });  
            }
            else{
                let data = this.state.data;
                listData.map(record =>{
                    data.push(record)
                });

                this.setState({ 
                    data:data
                });
            } 

            console.log('total records :' + listData.length);
            console.log('records :' + JSON.stringify(listData[0]));
            
        }
    }
    

    render() {
        const {norecord, data, selected,fairover} = this.state 
 
        this.options = {
            defaultSortName: 'refercount',  
            defaultSortOrder: 'desc' 
          };

        return (
            <div className="container-fluid site-container">
                <div className="career-header-wrapper d-flex flex-row align-items-center">
                    <div className="container">
                        <div className="career-container">
                            <div className="career-header">
                                <h1>Top referers</h1>
                            </div>
                        </div>
                    </div>
                </div>
              {
                  fairover ? <div className="referral-body-wrapper">
                  <div className="container">
                      Thanks for your support. This event is over, if you want to apply a job at Axrail, please click <a href='/build'>here</a>
                  </div> 
                </div> : null
              }

            {  data && data.length >0 && !fairover  ? 
                <div className="referral-body-wrapper">
                    <div className="">
                        
                        <BootstrapTable data={data}  options={ this.options}
                        fetchInfo={{dataTotalSize: 100}}
                        striped hover pagination={ false } >
                            <TableHeaderColumn width="0px" isKey hidden dataField='id' dataFormat={(cell)=><a href={"/listing/"+data.id}>{cell}</a>}>ID</TableHeaderColumn>
                            <TableHeaderColumn width="35%" dataField='firstname'  >First Name</TableHeaderColumn>
                            <TableHeaderColumn width="35%" dataField='lastname'  >Last Name</TableHeaderColumn>
                            <TableHeaderColumn width="35%" dataField='refercount' >Count</TableHeaderColumn>
                            
                            
                        </BootstrapTable>
                        
                    </div>
                </div> : 
                !fairover ? 
                <div className="referral-body-wrapper">
                    <div className="container">
                        Start referring your friends to join us and stand a chance to win a Amazon Dot if you are the top referer by 2pm today.
                    </div> 
                </div>  : null
            } 
    

     
        </div>
        )
    }
}

export default LandingPage
