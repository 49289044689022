import React from 'react';
import './assets/css/main.css'
import TopBar from './components/TopBar'
import { Switch, Route, Redirect } from 'react-router-dom'
import LandingPage from './pages/LandingPage';
import CareerPage from './pages/CareerPage';
import JobDetPage from './pages/JobDetPage';
import BuildPage from './pages/BuildPage';
import OTPPage from './pages/OTPPage';
import TopReferralPage from './pages/TopReferralPage';
import Page404 from './pages/Page404'; 

const App = () => {
  return (
    <div >
			{/* header */} 
			<TopBar/>
			{/* main */}
			<main>
				<Switch>
					<Route exact path='/' render={()=><Redirect to="/landing"/>}/>
					<Route exact path='index.html' render={()=><Redirect to="/landing"/>}/>
                    <Route exact path='/career' component={CareerPage} /> 
                    <Route exact path='/career/:id' component={JobDetPage} /> 
                    <Route exact path='/build' component={BuildPage} /> 
                    <Route exact path='/verify/:id' component={OTPPage}  /> 
                    <Route exact path='/topreferral' component={TopReferralPage} /> 
					<Route exact path='/landing' component={LandingPage} /> 
                    <Route exact path='/404' component={Page404} /> 
                    <Route render={()=><Redirect to="/404"/>} /> 
				</Switch>
			</main>
			{/* footer */}
      <footer>
          <div className="container">
              <div className="footer-wrapper d-flex flex-row align-items-center">
                  <div className="logo-wrapper d-flex flex-column align-items-center order-1 order-md-0">
                      <div className="logo-bg purple"></div>
                      <div className="copyright-text">© Axrail Pte Ltd</div>
                  </div>
                  <div className="nav-wrapper"> 
                  </div>
                  <div className="hide action-wrapper d-flex flex-row align-self-end order-2 order-md-2">
                      <a href="#" className="scrolltop-wrapper">
                          <div className="caret-wrapper">
                              <i className="up"></i>
                          </div>
                          <div className="caret-wrapper">
                              <i className="up"></i>
                          </div>
                          <div className="green-text">SCROLL UP</div>
                      </a>
                  </div>
              </div>
          </div>
      </footer>
        <div className="modal fade" id="cookieModal" tabIndex="-1" role="dialog" aria-labelledby="cookieModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                
                  <div className="modal-body">
                      We use cookies and similar technologies to recognize your repeat visits and preferences, to measure the effectiveness of
                      campaigns, and improve our websites. For settings and more information about cookies, view our Cookie
                      Policy. By clicking "I accept" on this banner or using our site, you consent to the use of cookies.
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="white-button" data-dismiss="modal">Accept</button>
                  </div>
              </div>
          </div>
      </div>

      <script src="assets/js/main.js"></script>
      
		</div>
  );
}

export default App;
